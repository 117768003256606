import { ProduitType } from '@innedit/innedit-type';
import { PageProps } from 'gatsby';
import { ProduitData } from 'packages/innedit';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import Form from '~/containers/Espace/Form';
import params from '~/params/produit/index.json';
import { ReducerCMSProps } from '~/reducers/cms';
import TemplateEspace from '~/templates/espace';
import requireEspace, { EspaceProps } from '~/utils/requireEspace';
import { UserProps } from '~/utils/requireUser';

const PageEspaceProduitsCreate: FC<PageProps & EspaceProps & UserProps> = ({
  espace,
  user,
  params: { espaceId },
}) => {
  const model = new ProduitData({
    espace,
    espaceId,
    params,
  });

  const channels = useSelector(
    (state: { cms: ReducerCMSProps }) => state.cms.channels,
  );

  if (!channels) {
    return (
      <TemplateEspace espace={espace} user={user}>
        <div>Chargement en cours des paramètres</div>
      </TemplateEspace>
    );
  }

  const initializeData: Partial<ProduitType> = {
    channels:
      channels && channels.length > 0
        ? channels.reduce((acc, value) => {
            const newAcc = { ...acc };
            newAcc[value.id] = {
              isVisible: true,
            };

            return newAcc;
          }, {} as { [key: string]: { isVisible: boolean } })
        : {},
  };

  return (
    <TemplateEspace espace={espace} user={user}>
      <Form
        docId={model.getNewDocId()}
        initializeData={initializeData}
        model={model}
        type="create"
      />
    </TemplateEspace>
  );
};

export default requireEspace(PageEspaceProduitsCreate);
